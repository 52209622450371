import { Component } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import Form from "react-bootstrap/Form"
import { TbFilterSearch, TbBrandAirbnb, TbBrandBooking } from 'react-icons/tb'
import { FaRegBell, FaUserCircle, FaLocationArrow, FaArrowLeft, FaSearchLocation, FaHireAHelper } from 'react-icons/fa'
import { PiUserSwitchBold } from 'react-icons/pi'
import { BsInfoCircle } from 'react-icons/bs'
import Checkmark from '../images/checkmark.svg'
import Calendar from './calendar'
import NodeTypes from './nodeTypes'
import './topMenu.css'

class TopMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            foul: false,
            showFoul: false,
            info: [
                { id: 0, displayInfo: false, displayMsg: false, msg: 'Click "Check Address" to validate and select the address.', dir: 'left', val: '60px' }
            ]
        }

        this.handleMonthChanged = this.handleMonthChanged.bind(this)
        this.handleDaySelected = this.handleDaySelected.bind(this)
        this.handleMonthSelected = this.handleMonthSelected.bind(this)
        this.handleOptionsSelected = this.handleOptionsSelected.bind(this)
        this.handleResetOptions = this.handleResetOptions.bind(this)
    }

    handleSettings() {
        this.props.onSettings()
    }

    handleModeSelect(mode) {
        if (mode === 'contactus') {
            this.props.onMode('contactus')
        } else if(mode === 'about') {
            window.open(`${window.location.origin}/about.html`, '_blank', 'noopener')
            this.props.onMode()
        } else {
            if (this.props.user || mode === 'fan') {
                this.props.onMode(mode)
            } else {
                this.setState({ foul: true })
                setTimeout(() => this.setState({ showFoul: true }), 250)
            }
        }
    }

    handleLocationCheck() {
        let info = [...this.state.info]
        info.splice(0, 1, { ...this.state.info[0], displayInfo: false, displayMsg: false })
        this.setState({ info })
        this.props.onLocCheck()
    }

    handleSearch() {
        this.props.onSearch()
    }

    handleNotificationsSelect() {
        this.props.onNotifications()
    }

    handlePrefSelect() {
        this.props.onPref()
    }

    handleMonthChanged(dir) {
        this.props.onMonthChanged(dir)
    }

    handleDaySelected(day) {
        this.props.onDaySelected(day)
    }

    handleMonthSelected() {
        this.props.onMonthSelected()
    }

    handleOptionsSelected(node, cat) {
        this.props.onOptionsSelected(node, cat)
    }

    handleGetMyLoc() {
        let info = [...this.state.info]
        info.splice(0, 1, { ...this.state.info[0], displayInfo: false, displayMsg: false })
        this.setState({ info })
        this.props.onGetMyLoc()
    }

    handleCategoriesChanged(c) {
        this.props.onCategoryChanged(c)
    }

    handleResetOptions() {
        this.props.onResetOptions()
    }

    handleToggleChanged(val) {
        this.props.onToggle(val)
    }

    handleAll() {
        this.props.onAll()
    }

    handleLocChanged(event) {
        let info = [...this.state.info]
        info.splice(0, 1, { ...this.state.info[0], displayInfo: event.target.value.length > 0, displayMsg: false })
        this.setState({ info })
        this.props.onLocChanged(event)
    }

    handleInfo(id) {
        let info = [...this.state.info]
        info.splice(id, 1, { ...this.state.info[0], displayMsg: !this.state.info[id].displayMsg })
        this.setState({ info })
    }

    handleAbnb() {
        this.props.onAbnb()
    }

    handleBooking() {
        this.props.onHotels()
    }

    handleHiring() {
        this.props.onHiring()
    }

    getInfo(id) {
        return <div className='Info-Container' style={{ [this.state.info[id].dir]: this.state.info[0].val }}>
            <div className='Info-Row Info-Text'>{this.state.info[id].msg}</div>
            <div className="Info-Flex-Wrap">
                <div className='Info-Button Info-Button-Save' onClick={() => this.handleInfo(id)}>Got It!</div>
            </div>
        </div>
    }

    getSettings() {
        return <div className='Settings-Container' style={{ height: this.state.foul ? '410px' : '340px' }}>
            <div className='Settings-Row'>
                <div className={`Settings-Button Settings-Fan${this.props.mode === 'fan' ? ' Settings-Current' : ''}`} onClick={() => this.handleModeSelect('fan')}>Fan Mode</div>
            </div>
            <div className='Settings-Row'>
                <div className={`Settings-Button Settings-${this.props.user ? 'Venue' : 'User'}${this.props.mode === 'Venue' ? ' Settings-Current' : ''}`} onClick={() => this.handleModeSelect('Venue')}>Venue Mode</div>
            </div>
            <div className='Settings-Row'>
                <div className={`Settings-Button Settings-${this.props.user ? 'Contributor' : 'User'}${this.props.mode === 'Contributor' ? ' Settings-Current' : ''}`} onClick={() => this.handleModeSelect('Contributor')}>Contributor Mode</div>
            </div>
            <div className='Settings-Row'>
                <div className={`Settings-Button Settings-${this.props.user ? 'Tix' : 'User'}${this.props.mode === 'tix' ? ' Settings-Current' : ''}`} onClick={() => this.handleModeSelect('tix')}>Ticket Agent</div>
            </div>
            <div className='Settings-Row'>
                <div className={`Settings-Button Settings-Contactus${this.props.mode === 'contactus' ? ' Settings-Current' : ''}`} onClick={() => this.handleModeSelect('contactus')}>Contact Us</div>
            </div>
            <div className='Settings-Row'>
                <div className={`Settings-Button Settings-About`} onClick={() => this.handleModeSelect('about')}>About GigLoca</div>
            </div>            
            {
                this.state.showFoul && <div className='Settings-Foul'>For an All-Access pass to Gigloca, <LinkContainer to='/signup'><span className='Settings-Link Settings-Link-Blue'>sign up</span></LinkContainer> or <LinkContainer to='/login'><span className='Settings-Link Settings-Link-Red'>log in</span></LinkContainer>!</div>
            }
        </div>
    }

    getSearch() {
        return <div className='Search-Container'>
            {this.props.onSearch && <div className='Settings-Row'>
                <Form.Group controlId="loc" className="form-group-lg">
                    <div className='Search-Spread'>
                        <div style={{ fontSize: '14pt', color: '#fff', cursor: 'pointer' }} onClick={() => this.handleSearch()}><FaArrowLeft /></div>
                        <div style={{ fontSize: '14pt', color: '#fff', cursor: 'pointer' }} onClick={() => this.handleSearch()}><FaSearchLocation /></div>
                    </div>
                    <Form.Label className='Search-Label'>Location<span style={{ marginLeft: '8px' }} onClick={() => this.handleGetMyLoc()}><FaLocationArrow /></span></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Search Location"
                        value={this.props.loc}
                        onChange={event => this.handleLocChanged(event)}
                        isValid={this.props.locIsGood}
                        isInvalid={!this.props.locIsGood}
                    />
                </Form.Group>
                <div className='Search-Row'>
                    <div className='TopMenu-Left'>
                        <div className='Search-Label Search-Link' onClick={() => this.handleLocationCheck(this.state.loc)}>Check Address{this.props.locIsGood && <span style={{ marginLeft: '5px' }}><img src={Checkmark} alt="Verified" className='Search-Location-Checkmark' /></span>}</div>
                        {this.state.info[0].displayInfo && <div className='Info-Icon' onClick={() => this.handleInfo(0)}><BsInfoCircle /></div>}
                        {this.state.info[0].displayMsg && this.getInfo(0)}
                    </div>
                    <div style={{ width: '50%' }}>
                        <Form.Label style={{ color: '#fff' }}>Distance {this.props.dist} mi</Form.Label>
                        <Form.Range value={this.props.dist} onChange={this.props.onDistChange} />
                    </div>
                </div>
                {this.props.lodging && this.props.locIsGood && <div className='Search-Row Lodging-Spread'>
                    <div className={`TopMenu-Left${this.props.hotel ? ' Lodging-Selected' : ' Lodging-Unselected'}`} onClick={() => this.handleBooking()}>
                        <div className='Lodging-Icon'><TbBrandBooking /></div>
                        <div className='Lodging-Icon'>Find a Hotel</div>
                    </div>
                    <div className={`TopMenu-Left${this.props.abnb ? ' Lodging-Selected' : ' Lodging-Unselected'}`} onClick={() => this.handleAbnb()}>
                        <div className='Lodging-Icon'><TbBrandAirbnb /></div>
                        <div className='Lodging-Icon'>Find a Rental Property</div>
                    </div>
                </div>}
                {this.props.onHiring && <div className='Search-Flex' style={{fontSize: '13pt', margin: '5px 0', color: '#fff'}}>
                    <Form.Check type='checkbox' value='hiring' label='Now Hiring' checked={this.props.hiring} onChange={() => this.handleHiring()} />
                    <div style={{ marginLeft: '8px' }}>{this.props.hiring && <div className='Bounce'><FaHireAHelper /></div>}</div>
                </div>}
            </div>
            }
            {
                this.props.calendar && <Calendar
                    month={this.props.month}
                    onDaySelected={this.handleDaySelected}
                    onMonthChanged={this.handleMonthChanged}
                    onMonthSelected={this.handleMonthSelected}
                    days={this.props.days} />
            }
            {
                this.props.options && <div><NodeTypes options={this.props.options} onOptionsSelected={this.handleOptionsSelected} pref={this.props.pref} onResetOptions={this.handleResetOptions} /></div>
            }
            {
                this.props.categories && <div className='Search-Row'>
                    {this.getCategories()}
                </div>
            }
            {
                this.props.toggle && <div style={{ width: '100%' }}>
                    <Form.Group className="form-group form-group-lg">
                        <Form.Label className='Search-Label form-label'>Express Intereste In:</Form.Label>
                    </Form.Group><div className='Search-Flex' style={{ paddingBottom: '30px' }}>
                        <Form.Check style={{ fontSize: '12pt', margin: '5px' }} type='radio' id='events' label='Open Gigs' checked={this.props.toggle === 'events'} onChange={() => this.handleToggleChanged('events')} />
                        <Form.Check style={{ fontSize: '12pt', margin: '5px' }} type='radio' id='events' label='Potential Gigs at Venues' checked={this.props.toggle === 'venues'} onChange={() => this.handleToggleChanged('venues')} />
                        <Form.Check style={{ fontSize: '12pt', margin: '5px' }} type='checkbox' id='booked' label='Include Booked Gigs to be a Backup' checked={this.props.all} onChange={() => this.handleAll()} />
                    </div>
                </div>
            }
        </div>
    }

    getCategories() {
        return this.props.categories ? <div>
            <Form.Group className="form-group form-group-lg">
                <Form.Label className='Search-Label form-label'>Categories</Form.Label>
            </Form.Group><div className='Search-Flex'>
                {
                    this.props.categories.map((c, cidx) => {
                        return <Form.Check style={{ fontSize: '12pt', margin: '5px', minWidth: '0px' }} key={`cat-${cidx}`} type='checkbox' id={c} label={c} checked={this.props.categoryIsChecked(c)} onChange={() => this.handleCategoriesChanged(c)} />
                    })
                }</div>
        </div> : null
    }

    render() {
        //console.log(this.state, this.props)
        return <div className={`TopMenu-Container TopMenu-BG-${this.props.mode}`}>
            <div className='TopMenu-Left'>
                {this.props.search && <div className='TopMenu-Item' onClick={() => this.handleSearch()}><TbFilterSearch /></div>}
            </div>
            <div className='TopMenu-Right'>
                {this.props.notifications ? <div className='TopMenu-Item' onClick={() => this.handleNotificationsSelect()}>
                    <FaRegBell />
                    {
                        this.props.notifications ? <div className='TopMenu-Notifications'>{this.props.notifications}</div> : null
                    }
                </div> : null}
                {this.props.onSettings ? <div className='TopMenu-Item' onClick={() => this.handleSettings()}><PiUserSwitchBold /></div> : <div className='TopMenu-Item'></div>}
                {
                    this.props.user ? (this.props.user.image ? <div style={{ display: 'flex' }}>
                        <div onClick={() => this.handlePrefSelect()}>
                            <img className={`TopMenu-Img${this.props.user.premium ? ' Profile-Premium' : ''}`} src={`https://localtalent-upload.s3.amazonaws.com/public/${this.props.user.image}`} alt={`Welcome ${this.props.user.firstName}`} />
                        </div>
                    </div> :
                        <div className={`TopMenu-User${this.props.user.premium ? ' Profile-Premium' : ''}`} onClick={() => this.handlePrefSelect()}><div style={{ fontSize: '23pt', marginTop: '-11px' }}>{this.props.user.firstName.substring(0, 1)}</div></div>) :
                        <div className='TopMenu-User' onClick={() => this.handlePrefSelect()}><FaUserCircle /></div>

                }
            </div>
            {this.props.displaySettings && this.getSettings()}
            {this.props.displaySearch ? this.getSearch() : null}
        </div>
    }
}

export default TopMenu