import { Component } from 'react'
import { API } from 'aws-amplify'
import withRouter from '../components/withRouter'
import TopMenu from '../components/topMenu'
import moment from 'moment'
import SmallCard from '../components/smallCard'
import { AiFillCloseCircle } from 'react-icons/ai'
import { HiRefresh } from 'react-icons/hi'
import { FaArrowLeft } from 'react-icons/fa'
import { BsQrCode } from 'react-icons/bs'
import Logo from '../images/GigLoca_Logo_Light.png'
import './node.css'

class Notifications extends Component {
    constructor(props) {
        super(props)

        this.state = {
            bounce: false,
        }

        this.handleOnPrefSelect = this.handleOnPrefSelect.bind(this)
        this.handleDisplaySettings = this.handleDisplaySettings.bind(this)
        this.handleModeChange = this.handleModeChange.bind(this)
        this.handleOnNotificationsSelect = this.handleOnNotificationsSelect.bind(this)
        this.handleMessageSelect = this.handleMessageSelect.bind(this)
    }

    componentDidMount() {
        if (this.props.isAuthenticated && this.props.user && this.props.user.active) {
            if (this.props.user.dyk && this.props.user.dyk.notifications) {
                this.props.setMode('notifications')
                this.handleDYK()
            } else {
                this.handleRefresh()
            }
        } else {
            this.setState({ splash: false, bounce: true })
            setTimeout(() => {
                this.handleNavigate('main')
            }, 3000)
        }
    }

    handleNavigate(page) {
        this.props.onAddHistory(`notifications`)
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleBack() {
        this.props.onBack()
    }

    handleDYK() {
        setTimeout(() => this.handleNavigate(`DYK`, true), 150)
    }

    handleOnPrefSelect() {
        this.handleNavigate('profile')
    }

    handleDisplaySettings() {
        this.setState({ displaySettings: !this.state.displaySettings })
    }

    handleModeChange(mode) {
        if (mode) {
            this.props.setMode(mode)
            if (mode === 'contactus') {
                this.handleNavigate('contactus')
            } else if (mode === 'tix') {
                this.handleNavigate('tixcheck')
            } else if (mode !== 'fan') {
                this.handleNavigate(`my/${mode}s`)
            } else this.handleNavigate('')
        }
        this.setState({ displaySettings: false })
    }

    handleOnNotificationsSelect() {
        this.props.onBack()
    }

    handleRefresh() {
        API.get('lt', 'user')
            .then(resp => this.props.setUser(resp.data))
            .catch(err => console.log(err))
    }

    handleClearAll() {
        API.put('lt', 'dismiss')
            .then(resp => {
                this.props.setUser(resp.user)
            })
            .catch(err => console.log(err))
    }

    handleDismiss(id) {
        API.put('lt', 'dismiss', { body: { id } })
            .then(resp => {
                let user = this.props.user
                let nidx = user.notifications ? user.notifications.findIndex(n => n.id === id) : -1
                if (nidx > -1) {
                    user.notifications.splice(nidx, 1)
                    this.props.setUser(user)
                }
            })
            .catch(err => console.log(err))
    }

    handleMessageSelect(id, type) {
        if (type === 'venue') {
            this.handleNavigate(`venue/${id}`)
        } else if (type === 'contributor' || type === 'supplier') {
            this.handleNavigate(`contributor/${id}`)
        } else this.handleNavigate(`event/${id}`)
    }

    handleTixSelect(eid) {
        this.handleNavigate(`tix/${eid}`)
    }

    getBounce() {
        return <div className='Pref-Bounce'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
            <div className='Node-Content'>Enjoy all the features Gigloca has to offer by Logging In or Signing Up.</div>
        </div>
    }

    getNotifications() {
        return <div>
            <div className='Node-Row Node-Flex-Spread'>
                <div className='Node-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-13px' }}><FaArrowLeft /></div></div>
                <div className='Event-Icon-Container' onClick={() => this.handleRefresh()}>
                    <div className='Event-Icon'><HiRefresh /></div>
                    <div className='Event-Icon-Text'>Refresh</div>
                </div>
                <div className='Event-Icon-Container' onClick={() => this.handleClearAll()}>
                    <div className='Event-Icon'><AiFillCloseCircle /></div>
                    <div className='Event-Icon-Text'>Dismiss All</div>
                </div>
            </div>
            <div style={{ color: '#fff' }} className='Node-Row Node-Title'>{`${this.props.user && this.props.user.notifications ? this.props.user.notifications.length : '0'} Notifications`}</div>
            <div className='Notifications-Content Node-Flex-Wrap'>
                {this.props.user && this.props.user.notifications && this.props.user.notifications.sort((a, b) => {
                    if (moment(a.created).isAfter(moment(b.created))) return -1
                    if (moment(a.created).isBefore(moment(b.created))) return 1
                    return 0
                }).map((n, nidx) => {
                    return <div key={`note-${nidx}`} className='Node-Row Notification-Container'>
                        <div className='Node-Row Node-Flex-Spread'>
                            <div className='Node-Flex'>
                                <div className='Notification-Date-Containter'>
                                    <div className='Notification-Date-Row'>{moment(n.created).format('ll')}</div>
                                    <div className='Notification-Date-Row'>{moment(n.created).format('h:mm A')}</div>
                                </div>
                                <div className='Notification-Msg'>{n.message}</div>
                            </div>
                            <div className='Notification-Del-Icon' onClick={() => this.handleDismiss(n.id)}><AiFillCloseCircle /></div>
                        </div>
                        {(n.event || n.supplier || n.venue || n.image) ? <div className='Node-Row Node-Flex-Wrap'>
                            {n.venue && <SmallCard type='venue' data={n.venue} onSelect={this.handleMessageSelect} />}
                            {n.supplier && <SmallCard type='contributor' data={n.supplier} onSelect={this.handleMessageSelect} />}
                            {n.event && !n.tix && <SmallCard type='event' data={n.event} onSelect={this.handleMessageSelect} />}
                            {n.event && n.tix && <div className='MyTix-Container' onClick={() => this.handleTixSelect(n.event.id)}>
                                <div className='Node-Row Tix-Label'>{n.event.title}</div>
                                <div className='Node-Row MyTix-QR'><BsQrCode /></div>
                            </div>}
                            {n.image && <img className='SmallCard-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${n.image}`} alt="Welcome to Gigloca!" />}
                        </div> : null}
                    </div>
                })}
            </div>
        </div>
    }

    render() {
        //console.log(this.props.user || null)
        return <div className='Node-Container Node-Event'>
            <TopMenu
                mode={this.props.mode}
                onMode={this.handleModeChange}
                onPref={this.handleOnPrefSelect}
                notifications={this.props.user ? (this.props.user.notifications && this.props.user.notifications.length > 0 ? this.props.user.notifications.length : true) : null}
                onNotifications={this.handleOnNotificationsSelect}
                user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null}
                onSettings={this.handleDisplaySettings}
                displaySettings={this.state.displaySettings}
                pref={null} />
            {this.state.bounce ? this.getBounce() : this.getNotifications()}
        </div>
    }
}

export default withRouter(Notifications)