import { Component } from 'react'
import TopMenu from '../components/topMenu'
import Carousel from 'react-bootstrap/Carousel'
import Form from "react-bootstrap/Form"
import moment from 'moment'
import { API } from 'aws-amplify'
import QRCode from 'qrcode.react'
import withRouter from '../components/withRouter'
import Logo from '../images/GigLoca_Logo_Light.png'
import { HiRefresh } from 'react-icons/hi'
import { FaArrowLeft, FaUserAltSlash, FaUser } from 'react-icons/fa'
import { BsCalendarFill, BsClockHistory, BsInfoCircle, BsCartX } from 'react-icons/bs'
import { MdPersonSearch } from 'react-icons/md'
import { FcPaid } from 'react-icons/fc'
import './node.css'

class Tix extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: null,
            attending: null,
            bounce: false,
            invite: '',
            userFailed: false,
            userPassed: false,
            inviteImage: null,
            info: [
                { id: 0, displayInfo: false, displayMsg: false, msg: 'Gigloca will transfer owernship of this ticket to another Fan.  This may NOT be undone.\n1. Find fans with email search.\n2. Select ticket.\n3. Click on target profile to transfer ticket.', dir: 'bottom', val: '120px' }
            ],
            ticket: null,
            msg: '',
            displayMsg: false,
            activeIndex: 0
        }

        this.handleOnPrefSelect = this.handleOnPrefSelect.bind(this)
        this.handleDisplaySettings = this.handleDisplaySettings.bind(this)
        this.handleModeChange = this.handleModeChange.bind(this)
        this.handleOnNotificationsSelect = this.handleOnNotificationsSelect.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        if (this.props.isAuthenticated) {
            const { path } = this.props
            const id = path.id || null
            if (this.props.user && this.props.user.active && this.props.user.dyk && this.props.user.dyk.tix) {
                this.props.setMode('tix')
                this.handleDYK()
            } else {
                if (id) {
                    API.get('lt', 'user')
                        .then(resp => {
                            this.props.setUser(resp.data)
                            let aidx = resp.data && resp.data.attending ? resp.data.attending.findIndex(a => a.id === id) : -1
                            this.setState({ id, attending: aidx > -1 ? resp.data.attending[aidx] : null, ticket: aidx > -1 && resp.data.attending[aidx].tix ? resp.data.attending[aidx].tix[0] : null })
                        })
                        .catch(err => console.log(err))
                } else this.handleNavigate('')
            }
        } else {
            this.setState({ splash: false, bounce: true })
            setTimeout(() => {
                this.handleNavigate('main')
            }, 3000)
        }
    }

    handleNavigate(page) {
        if (this.state.id) this.props.onAddHistory(`/tix/${this.state.id}`)
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleBack() {
        this.props.onBack(this.state.refresh)
    }

    handleDYK() {
        setTimeout(() => this.handleNavigate(`DYK`, true), 150)
    }

    handleOnPrefSelect() {
        this.handleNavigate('profile')
    }

    handleDisplaySettings() {
        this.setState({ displaySettings: !this.state.displaySettings })
    }

    handleModeChange(mode) {
        if (mode) {
            this.props.setMode(mode)
            if (mode === 'contactus') {
                this.handleNavigate('contactus')
            } else if (mode === 'tix') {
                this.handleNavigate('tixcheck')
            } else if (mode !== 'fan') {
                this.handleNavigate(`my/${mode}s`)
            } else this.handleNavigate('')
        }
        this.setState({ displaySettings: false })
    }

    handleOnNotificationsSelect() {
        this.handleNavigate('notifications')
    }

    handleRefresh(tidx) {
        API.get('lt', 'user')
            .then(resp => {
                this.props.setUser(resp.data)
                let aidx = resp.data && resp.data.attending ? resp.data.attending.findIndex(a => a.id === this.state.id) : -1
                this.setState({ attending: aidx > -1 ? resp.data.attending[aidx] : null, ticket: aidx > -1 && tidx ? resp.data.attending[aidx].tix[tidx] : this.state.ticket })
            })
            .catch(err => console.log(err))
    }

    handleSendTix() {
        if (this.state.ticket.open) {
            API.post('lt', `tix/${this.state.id}`, { body: { tid: this.state.ticket.tid, gid: this.state.ticket.gid, email: this.state.invite } })
                .then(resp => {
                    this.setState({ userFailed: false, userPassed: true, activeIndex: this.state.activeIndex === this.state.attending.tix.length - 1 ? this.state.activeIndex - 1 : this.state.activeIndex })
                    this.handleRefresh()
                })
                .catch(err => {
                    this.setState({ msg: "Unable to share with user.", userFailed: true, userPassed: false })
                })
        } else this.setState({ msg: 'Ticket is no longer active', displayMsg: true })
    }

    handleChange(eventKey, event) {
        this.handleRefresh(eventKey)
        this.setState({ activeIndex: eventKey })
    }

    handleChangeEmail = event => {
        let info = [...this.state.info]
        info.splice(0, 1, { ...this.state.info[0], displayInfo: event.target.value.length > 0 })
        this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9-@. ]/g, "").toLowerCase(), userFailed: false, userPassed: false, info })
    }

    handleInfo(id) {
        let info = [...this.state.info]
        info.splice(id, 1, { ...this.state.info[id], displayMsg: !this.state.info[id].displayMsg, displayInfo: this.state.invite.length > 0 })
        this.setState({ info })
    }

    handleCheckEmail() {
        if (this.getEmailIsValid()) {
            API.get('lt', `email?email=${this.state.invite}`)
                .then(resp => {
                    if (resp.data.verified) {
                        this.setState({ userFailed: false, userPassed: true, inviteImage: resp.data.image })
                    } else {
                        this.setState({ userFailed: true, userPassed: false, inviteImage: null })
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) {
                        this.handleNavigate('main')
                    } else this.setState({ userFailed: true, msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                })
        } else this.setState({ userFailed: true })
    }

    handleMsgOK() {
        this.setState({ msg: '', displayMsg: false })
    }

    getEmailIsValid() {
        return this.state.invite.length > 2 && this.state.invite.indexOf('@') > 0 && this.state.invite.indexOf('.') > 1
    }

    getMessage() {
        return <div className='Node-Confirm'>
            <div className='Node-Row Confirm-Text'>{this.state.msg}</div>
            <div className="Node-Row Node-Flex-Wrap">
                <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleMsgOK()}>OK</div>
            </div>
        </div>
    }

    getBounce() {
        return <div className='Pref-Bounce'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
            <div className='Node-Content'>Become part of the fun by Logging In or Joining GigLoca.</div>
        </div>
    }

    getInfo(id) {
        return <div className='Info-Container' style={{ [this.state.info[id].dir]: this.state.info[id].val }}>
            <div className='Info-Row Info-Text'>{this.state.info[id].msg}</div>
            <div className="Info-Flex-Wrap">
                <div className='Info-Button Info-Button-Save' onClick={() => this.handleInfo(id)}>Got It!</div>
            </div>
        </div>
    }

    render() {
        //console.log(this.state)
        return this.state.bounce ? this.getBounce() : <div className='Node-Container Node-Tix'>
            <TopMenu
                mode='fan'
                onMode={this.handleModeChange}
                onPref={this.handleOnPrefSelect}
                notifications={this.props.user ? (this.props.user.notifications && this.props.user.notifications.length > 0 ? this.props.user.notifications.length : true) : null}
                onNotifications={this.handleOnNotificationsSelect}
                user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null}
                onSettings={this.handleDisplaySettings}
                displaySettings={this.state.displaySettings}
                pref={null} />
            <div className={window.innerWidth > 700 ? 'Node-Outer' : ''}>
                <div className='Node-Content-2'>
                    <div className='Node-Row Node-Flex-Spread'>
                        <div className='Node-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-13px' }}><FaArrowLeft /></div></div>
                        <div className='Event-Icon-Container' onClick={() => this.handleRefresh()}>
                            <div className='Event-Icon'><HiRefresh /></div>
                            <div className='Event-Icon-Text'>Refresh</div>
                        </div>
                    </div>
                    <div style={{ color: '#fff' }} className='Node-Row Node-Title Node-Link' onClick={() => this.handleNavigate(`event/${this.state.attending && this.state.attending.id ? this.state.attending.id : ''}`)}>{this.state.attending && this.state.attending.title ? this.state.attending.title : 'Event'}</div>
                    <div style={{ color: '#fff' }} className='Node-Row Tix-Detail'>{this.state.attending && this.state.attending.details ? this.state.attending.details : 'Event'}</div>
                    {this.state.attending && this.state.attending.startTime && this.state.attending.stopTime && <div className='Node-Row Node-Flex-Spread MyTix-Bar' style={{ color: '#fff' }}>
                        <div className='Node-Flex'>
                            <div className='Node-Addr-Icon'><BsCalendarFill /></div>
                            <div className='Pref-Text'>{this.state.attending.startTime ? moment(this.state.attending.startTime).format('MMM DD') : ''}</div>
                        </div>
                        <div className='Node-Flex'>
                            <div className='Node-Addr-Icon'><BsClockHistory /></div>
                            <div className='Pref-Text'>{this.state.attending.startTime ? moment(this.state.attending.startTime).format('h:mm A') : ''}</div>
                        </div>
                        <div className='Node-Flex'>
                            <div className='Node-Addr-Icon'><BsCalendarFill /></div>
                            <div className='Pref-Text'>{this.state.attending.stopTime ? moment(this.state.attending.stopTime).format('MMM DD') : ''}</div>
                        </div>
                        <div className='Node-Flex'>
                            <div className='Node-Addr-Icon'><BsClockHistory /></div>
                            <div className='Pref-Text'>{this.state.attending.startTime ? moment(this.state.attending.stopTime).format('h:mm A') : ''}</div>
                        </div>
                    </div>}
                    <div style={{ color: '#ccc' }} className='Node-Row Node-Title'>{`${this.state.attending && this.state.attending.tix ? this.state.attending.tix.length : '0'} Ticket${this.state.attending && this.state.attending.tix && this.state.attending.tix.length > 1 ? 's' : ''}`}</div>
                    <div className='Node-Row Node-Flex-Wrap'>
                        {
                            this.state.attending && this.state.attending.tix ? <div className='Node-Row'>
                                <div className='Node-Row Node-Flex-Wrap'><Carousel className='MyTix-Tix' interval={null} controls={false} slide={true} touch={true} onSelect={this.handleChange} activeIndex={this.state.activeIndex}>
                                    {this.state.attending.tix.map((t, tidx) => {
                                        return <Carousel.Item key={`ci-${tidx}`}>
                                            <div className='Node-Row'>
                                                <div className='Node-Row MyTix-Label'>{t.cat}</div>
                                                <div className='Node-Row Node-Flex-Wrap'><QRCode size={256} value={`{"eid": "${this.state.attending.id}", "tid": "${t.tid}", "gid": "${t.gid}"}`} /></div>
                                                <div className='Node-Row MyTix-Label Node-Flex-Spread'><div>{t.label}</div><div>Ticket {t.id}</div></div>
                                                <div className='Node-Row MyTix-Label'>{t.open ? 'Open' : 'Checked In'}</div>
                                                <div className='Node-Row MyTix-Label Node-Flex-Wrap'>
                                                    <div>${t.price || '0.00'}</div>
                                                    {this.state.attending && this.state.attending.tickets && this.state.attending.tickets.payment && <div style={{ fontSize: '16pt' }}>{this.state.pending ? <BsCartX /> : <FcPaid />}</div>}
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    })}
                                </Carousel></div>
                                <div className='Node-Row' style={{ paddingBottom: '20px' }}>
                                    <Form.Group controlId="invite" style={{ marginLeft: '5px', marginRight: '5px' }} className="form-group form-group-lg">
                                        <div className='Node-Row Node-Flex'>
                                            <Form.Label className='Pref-Label'>Transfer Tix to a Friend</Form.Label>
                                            {this.state.info[0].displayInfo && <div className='Info-Icon' onClick={() => this.handleInfo(0)}><BsInfoCircle /></div>}
                                            {this.state.info[0].displayMsg && this.getInfo(0)}
                                        </div>
                                        <div className='Node-Row Node-Flex'>
                                            <Form.Control
                                                placeholder="User Email"
                                                type="email"
                                                value={this.state.invite}
                                                onChange={this.handleChangeEmail}
                                                isValid={this.getEmailIsValid()}
                                            />
                                            <div className='My-Link-Icon' onClick={() => this.handleCheckEmail()}><MdPersonSearch /></div>
                                            {this.state.userFailed ? <div className='My-Link-Icon' style={{ color: '#ff0000' }}><FaUserAltSlash /></div> : (this.state.userPassed ? <div style={{ marginLeft: '10px' }} onClick={() => this.handleSendTix()}>{this.state.inviteImage ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${this.state.inviteImage}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div> : null)}
                                        </div>
                                    </Form.Group>
                                </div>
                            </div> : null
                        }
                    </div>
                </div>
            </div>
            {
                this.state.displayMsg && this.getMessage()
            }
        </div>
    }
}

export default withRouter(Tix)